/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";

import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          email
          author {
            name
            summary
          }
          social {
            twitter
          }
        }
      }
    }
  `);

  const { author, social, email } = data.site.siteMetadata;
  return (
    <div className="flex justify-center items-center space-x-4 h-full mb-4 px-4 py-2 rounded-xl shadow-xl bg-gradient-to-tr from-green-100 to-blue-200 dark:from-green-700 dark:to-blue-700">
      <StaticImage
        src="../../content/assets/profile.png"
        width={100}
        height={100}
        quality={90}
        alt={author.name}
        className="flex-none"
      />
      <div className="flex-auto">
        <p className="mb-1">
          Written by{" "}
          <a href={`https://twitter.com/${social.twitter}`}>{author.name}</a>.{" "}
          {author.summary}
        </p>
        <a
          href={`mailto:${email}`}
          className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
            />
          </svg>{" "}
          Email
        </a>
      </div>
    </div>
  );
};

export default Bio;
